import { injectHtml } from '../../../../modules/interact-with-html'
import consoleLogger from '../../../../modules/console-logger'


const checkInjectSelector = ({slot}) => {
  const [consoleLog] = consoleLogger('AdsInject')
  if (!slot && !slot.id && !slot.inject && !slot.inject.selector) {
    consoleLog('WARNING! No slot or inject options', slot, 'AdsLog')
    return false
  }
  consoleLog('slot or inject options available', slot, 'AdsLog')

  if (slot.inject?.excludePaths && Array.isArray(slot.inject.excludePaths)) {
    const checkInjectionPaths = slot.inject.excludePaths.some((path) => {
      const pattern = new RegExp(path);
      if (pattern.test(location.pathname)) {
        return true
      }
    })
    if (checkInjectionPaths === true) {
      consoleLog('WARNING! - Url excluded', slot.inject.excludePaths, 'AdsLog')
      return false
    }
    consoleLog('WARNING! - Url excluded', slot.inject.excludePaths, 'AdsLog')
  }

  if (slot.inject?.paths && Array.isArray(slot.inject.paths)) {
    const checkInjectionPaths = slot.inject.paths.some((path) => {
      const pattern = new RegExp(path);
      if (pattern.test(location.pathname)) {
        return true
      }
    })
    if (checkInjectionPaths === false) {
      consoleLog('WARNING! Url not fit for inject', slot.inject.paths, 'AdsLog')
      return checkInjectionPaths
    }
    consoleLog('Url fit for inject', slot.inject.paths, 'AdsLog')
  }

  const injectElement = slot?.inject?.selector && document.querySelector(slot?.inject?.selector)
  if (!injectElement) {
    consoleLog('WARNING! No inject element found', slot?.inject?.selector, 'AdsLog')
    return false
  }
  consoleLog('inject element found', slot.inject.selector, 'AdsLog')
  consoleLog('inject element', injectElement, 'AdsLog')

  return true
}

const initAdsInjectAd = (slot) => {
  const [consoleLog] = consoleLogger('AdsInject')

  if (!checkInjectSelector({slot})) {
    consoleLog('WARNING! No slot or inject options', slot, 'AdsLog')
    return false
  }

  consoleLog('Init inject', slot.id, 'AdsLog')

  let customReferenceNode
  if (slot.inject?.selectorsPosition) {
    customReferenceNode = document.querySelectorAll(slot.inject.selector)[slot.inject.selectorsPosition - 1]

    if (!customReferenceNode) {
      consoleLog('No custom reference node found', slot.inject.selectorsPosition, 'AdsLog')
      return false
    }
  }

  const childNode = document.createElement('div')
  childNode.id = slot.id
  childNode.style.cssText = slot.inject?.styleAd
  const parentNode = document.createElement('div')
  parentNode.style.cssText = slot.inject?.styleParent
  parentNode.appendChild(childNode)
  injectHtml({
    injectPosition: slot.inject?.position,
    referenceSelector: slot.inject?.selector,
    customReferenceNode: customReferenceNode,
    newNode: parentNode,
    consoleLoggerId: 'initAdsInjectAd',
  })
  consoleLog('Ad injected SUCCESS', { id: slot?.id, inject: slot?.inject }, 'AdsLog')
}

const triggerLazyLoadElement = (slot) => {
  if (slot.inject && slot.inject.selector) {
    return document.querySelector(slot.inject.selector)
  }
  return false
}

export default initAdsInjectAd

export { triggerLazyLoadElement, checkInjectSelector }
