import consoleLogger from '../console-logger'

const [consoleLog] = consoleLogger('InteractHtml')

const injectPositions = {
  AFTER: 0,
  BEFORE: 1,
  BEFORE_PARENT: 2,
  FIRST_OF_CHILD: 3,
  // CHILD: 2,
}

const injectHtml = ({
  referenceSelector,
  newNode,
  injectPosition,
  customReferenceNode,
  consoleLoggerId = undefined,
}) => {
  consoleLog('Checking selector..', referenceSelector, consoleLoggerId)

  const referenceNode = customReferenceNode || document.querySelector(referenceSelector)
  if (!referenceNode) {
    consoleLog('Selector not found: ', referenceSelector, consoleLoggerId)
    return false
  }
  consoleLog('Selector found: ', referenceSelector, consoleLoggerId)
  switch (injectPosition) {
    case injectPositions.AFTER: {
      referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling)
      break
    }
    case injectPositions.BEFORE: {
      referenceNode.parentNode.insertBefore(newNode, referenceNode)
      break
    }
    case injectPositions.BEFORE_PARENT: {
      referenceNode.parentNode.parentNode.insertBefore(newNode, referenceNode.parentNode)
      break
    }
    case injectPositions.FIRST_OF_CHILD: {
      referenceNode.insertBefore(newNode, referenceNode.firstChild)
    }
    default: {
      consoleLog('ERROR: ', 'invalid inject position!', consoleLoggerId)
      return false
    }
  }
}

export { injectHtml, injectPositions }
