import isServerSide from '../../../../modules/is-server-side'

const configTargets = ({configs}) => {
  let targets = {}
  if (!configs || !Array.isArray(configs) || isServerSide()) {
    return targets
  }
  const currentPath = window.location.pathname

  configs.forEach((target) => {
    if (target.paths && Array.isArray(target.paths)) {
      const isTarget = target.paths.some((path) => {
        const pattern = new RegExp(path);
        if (pattern.test(currentPath)) {
          return true
        }
      })
      if (isTarget) {
        targets = {
          ...targets,
          ...target.keyValues
        }
      }
    }
  })
  return targets
}

export default configTargets
