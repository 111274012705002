import handlerWindowDlab from '../handler-window-dlab'
import md5 from 'crypto-js/md5'
import sha256 from 'crypto-js/sha256'
import sha1 from 'crypto-js/sha1'

const getUserData = ({ configs }) => {
  if (!configs) {
    return
  }
  const { dlabOption } = handlerWindowDlab('user')
  const email = configs.email && configs.email()
  if (email) {
    dlabOption['email'] = {
      _: email,
      md5: md5(email),
      sha256: sha256(email),
      sha1: sha1(email),
    }
  }
}

export default getUserData
