import isTestTarget from '../is-test-target'
import handlerWindowDlab from '../handler-window-dlab'
import configTargets from '../config-targets'

const targets = ({ configs, googletag, testValue, targetsKeyPrefix, adTargets }) => {
  const { dlabOption } = handlerWindowDlab('ads')
  adTargets = adTargets || (window.dlab && window.dlab.adTargets) || []
  adTargets = {
    ...adTargets,
    ...configTargets({configs})
  }


  if (isTestTarget()) {
    adTargets['test'] = testValue || 'true'
  }
  dlabOption['targets'] = adTargets

  for (const externalKey in adTargets) {
    let key = externalKey
    let value = adTargets[key]
    if (targetsKeyPrefix && key !== 'test') {
      key = targetsKeyPrefix + '_' + key
    }

    if (value && !Array.isArray(value)) {
      value = value.toString()
    }

    googletag.pubads().setTargeting(key, value)
  }
}

export default targets
