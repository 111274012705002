import union from 'lodash/union'
import consoleLogger from '../../../../modules/console-logger'

let excludedAdUnit = []

const customRulesUrls = ({ googletag, urls = [], excludedLabels = [] }) => {
  const [consoleLog] = consoleLogger('AdsCustomRules')
  let noAds = false

  const currentUrl = location.href
  const currentPath = location.pathname

  urls.forEach((customRulesUrl) => {
    consoleLog('Custom Rules for URL', customRulesUrl, 'Ads')
    let match = false

    if (customRulesUrl.url) {
      match = customRulesUrl.exact ? currentUrl == customRulesUrl.url : currentUrl.includes(customRulesUrl.url)
    }

    if (customRulesUrl.paths && Array.isArray(customRulesUrl.paths)) {
      const checkInjectionPaths = customRulesUrl.paths.some((path) => {
        const pattern = new RegExp(path);
        if (pattern.test(currentPath)) {
          return true
        }
      })
      match = checkInjectionPaths
    }

    if (match) {
      if (customRulesUrl.injectCode) {
        if (typeof customRulesUrl.injectCode === 'function') {
          consoleLog('Custom Rules for URL', 'Inject Code ' + typeof customRulesUrl.injectCode, 'Ads')
          customRulesUrl.injectCode()
        } else {
          consoleLog('Custom Rules for URL', 'InjectCode not a function!', 'Ads')
        }
      }

      if (customRulesUrl.slots && customRulesUrl.slots.length > 0) {
        return
      }
      if (customRulesUrl.noAds) {
        noAds = true
        consoleLog('Custom Rules for URL', 'Hinder Ads on Page', 'Ads')
        return
      }
      if (customRulesUrl.forceAds) {
        excludedLabels = []
        consoleLog('Custom Rules for URL', 'Forcing Ads on Page', 'Ads')
        return
      }
      if (customRulesUrl.customExclusionLabels) {
        excludedLabels = union(customRulesUrl.customExclusionLabels, excludedLabels)
        consoleLog('Custom Rules for URL', 'Using Custom Exclusion Labels: ' + excludedLabels.toString(), 'Ads')
        return
      }
      if (customRulesUrl.excludeAdUnit) {
        excludedAdUnit = excludedAdUnit.concat(customRulesUrl.excludeAdUnit)
        return
      }
    }
  })

  if (noAds) {
    return false
  }

  for (const key in excludedLabels) {
    googletag.pubads().setCategoryExclusion(excludedLabels[key])
  }

  return true
}

export default customRulesUrls
export { excludedAdUnit }
