import { useUserContext } from '../../../ContextUser'
import React, { useEffect, useMemo, useReducer, useState } from 'react'
import { addSpecialTargets } from '../../../../modules/ads-targets'
import Options from '../../../../config/options'
import prebidVideoSuccess from '../../../../bundles/BundleAds/vendor/prebid-magnite/modules/prebid-video-success'
import destroyAdSettings from '../../../../bundles/BundleAds/modules/destroy-ad-settings'
import ContextAds from '../../index'
import initAds from '../../../../bundles/BundleAds/modules/init-ads'

const AdsProvider = ({ meta, targeting, wait, showAds, update, children, forcedConfigs, ...props }) => {
  const {
    access: { isPlus },
    profile,
  } = useUserContext()
  const reducer = (state, action) => {
    switch (action) {
      case 'wait':
        return 'wait'
      case 'ready':
      case 'default-start':
        return 'ready'
    }
  }

  const [adsState, adsDispatch] = useReducer(reducer, wait ? 'wait' : 'ready')
  const [targets, setTargets] = useState({})
  const [localVideoTargetUri, setLocalVideoTargetUri] = useState('')

  const metaUrl = meta?.url

  useEffect(() => {
    if (metaUrl && showAds && isPlus !== undefined && adsState === 'ready') {
      const mergedTargets = {
        ...meta.adsTargeting,
        ...targeting,
      }
      addSpecialTargets(mergedTargets)

      const checkPlus = isPlus && profile

      mergedTargets['splus'] = checkPlus ? 'true' : 'false' //magic default/premium
      mergedTargets['splus_flag'] = checkPlus ? 'true' : 'false'

      if (mergedTargets !== targets) {
        setTargets(mergedTargets)
      }
      let excludedLabels = []
      if (isPlus && profile) {
        excludedLabels = [...Options.Ads.sPlusExcludedAdsLabels, ...excludedLabels]
      }

      const onPrebidVideoSuccess = (props) => {
        const custParams = prebidVideoSuccess(props)
        setLocalVideoTargetUri(custParams)
      }

      initAds({
        adTargets: mergedTargets,
        excludedLabels: excludedLabels,
        //onPrebidVideoSuccess: onPrebidVideoSuccess,
        forcedConfigs,
      })
    }

    return () => {
      destroyAdSettings()
    }
  }, [metaUrl, isPlus, adsState, update])

  const value = useMemo(() => ({ adsDispatch, targets, showAds: showAds, videoTargetUri: localVideoTargetUri }), [
    metaUrl,
    isPlus,
    adsState,
    targets,
    showAds,
    localVideoTargetUri,
  ])

  return (
    <ContextAds.Provider value={value} {...props}>
      {children}
    </ContextAds.Provider>
  )
}

export default AdsProvider
